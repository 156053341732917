import React, { useState, useEffect } from "react";
import { Table } from "antd";

const TesterOtpTable = ({ testerId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://gj2fi00268.execute-api.us-east-1.amazonaws.com/dev/testerwise-banned-app-otp-count/${testerId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (testerId) {
      fetchData();
    }
  }, [testerId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const columns = [
    { title: "Sender ID", dataIndex: "sender_id", key: "sender_id" },
    { title: "Count", dataIndex: "count(*)", key: "count" },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey={(record, index) => index}
    />
  );
};

export default TesterOtpTable;
