import React, { useEffect, useState } from "react";
import {
  Button,
  Layout,
  Menu,
  Dropdown,
  Modal,
  Form,
  Input,
  theme,
  Tabs,
  Grid,
} from "antd";
import axios from "axios";
import Leaderboard from "../tester-teamlead/leaderBoard";
import Stats from "../stats";
import SimCards from "./simcards";
import Testerstatistics from "../admin/testerStatistics";
import ConfigSettings from "./configSettings";
import FrequentApps from "./frequentlyUsed";
import Applications from "./application";
import TeamLeads from "./teamleads";
import Testers from "./testers";
import BlockedTesters from "./blockedTesters";
import SpecialTesterstatistics from "../special-testers-teamlead/testers-stats";
import AllTestersStats from "./paymentstats";
import Teams from "./teams";
import dashboardIcon from "../../assets/images/Bill.png";
import billIcon from "../../assets/images/Bill.png";
import leaderboardIcon from "../../assets/images/Leaderboard.png";
import countrywiseOTPCountIcon from "../../assets/images/Middle East.png";
import monthwiseOTPcountIcon from "../../assets/images/Calendar 32.png";
import teamIcon from "../../assets/images/Video Conference.png";
import teamleadIcon from "../../assets/images/Business Group.png";
import testerIcon from "../../assets/images/User Groups.png";
import simcardIcon from "../../assets/images/Chip Card.png";
import appsIcon from "../../assets/images/Keypad.png";
import otpStatsIcon from "../../assets/images/Combo Chart.png";
import TesterstatisticsIcon from "../../assets/images/Leaderboard.png";
import configSettingsIcon from "../../assets/images/Administrative Tools.png";
import frequentlyUsedIcon from "../../assets/images/Apps.png";
import SpecialTeamStats from "../tester-teamlead/specialTesterStats";
import AppWiseCount from "../tester-teamlead/appwiseCount";
import Billing from "./Billing";
import Dashboard from "./dashboard";
import CountryWiseOTPCount from "./countrywiseOTPCount";
import MonthWiseOTPCount from "./monthwiseOTPcount";
import AlltestersBannedAppsOtpCount from "./allTestersBannedAppsOtpCount";
import { LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Header, Content, Footer } = Layout;
const { Item } = Form;
const { useBreakpoint } = Grid;
const updatePassword = process.env.REACT_APP_API_UpdatePassword;
const TeamleadDashboard = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("1");
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const onLogout = () => {
    localStorage.removeItem("setupTime");
    localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
  };

  const handleMenuSelect = (key) => {
    setSelectedMenuItem(key);
  };

  const handlePasswordChange = () => {
    setChangePasswordVisible(true);
  };

  const handleCancel = () => {
    setChangePasswordVisible(false);
  };

  const handleFinish = async (values) => {
    try {
      const { currentPassword, newPassword } = values;
      const response = await axios.put(updatePassword, {
        userId: user.userId,
        oldPassword: currentPassword,
        newPassword: newPassword,
      });

      if (response.status === 200) {
        setChangePasswordVisible(false);
      } else {
        console.error("Password change failed");
      }
    } catch (error) {
      console.error("Error changing password:", error);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="changePassword"
        icon={<SettingOutlined />}
        onClick={handlePasswordChange}
      >
        Change Password
      </Menu.Item>
      <Menu.Item key="logout" onClick={onLogout} icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["1"]}
          items={items}
          onClick={({ key }) => handleMenuSelect(key)}
          style={{
            flex: 1,
            minWidth: 0,
          }}
        />
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button type="link">
            {user.name
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </Button>
        </Dropdown>
      </Header>
      <Content
        style={{
          padding: screens.xs ? "0 14px" : "0 48px",
        }}
      >
        <div
          style={{
            padding: screens.xs ? 3 : 24,
            minHeight: 380,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <View1 />
        </div>
      </Content>

      <Footer
        style={{
          textAlign: "center",
        }}
      >
        EON Testing Tool ©{new Date().getFullYear()} Created by EON Intelligence
      </Footer>

      {/* Change Password Modal */}
      <Modal
        title="Change Password"
        visible={changePasswordVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          name="changePasswordForm"
          onFinish={handleFinish}
          layout="vertical"
        >
          <Item
            name="currentPassword"
            label="Current Password"
            rules={[
              {
                required: true,
                message: "Please input your current password!",
              },
            ]}
          >
            <Input.Password />
          </Item>
          <Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
            ]}
          >
            <Input.Password />
          </Item>
          <Item
            name="confirmNewPassword"
            label="Confirm New Password"
            dependencies={["newPassword"]}
            rules={[
              {
                required: true,
                message: "Please confirm your new password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Item>
          <Item>
            <Button
              type="secondary"
              className="bg-[blue] text-[white]"
              htmlType="submit"
            >
              Change Password
            </Button>
          </Item>
        </Form>
      </Modal>
    </Layout>
  );
};

const View1 = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const screens = useBreakpoint();
  const onChangeTab = (key) => {
    setSelectedMenuTabItem(key);
  };
  const [tabPosition, setTabPosition] = useState("left");
  const [selectedMenuTabItem, setSelectedMenuTabItem] = useState("1");
  useEffect(() => {
    const handleResize = () => {
      // Switch to horizontal tabs for small screens
      if (window.innerWidth <= 768) {
        setTabPosition("top");
      } else {
        // Default to vertical tabs for larger screens
        setTabPosition("left");
      }
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        padding: 24,
        minHeight: 380,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <Tabs
        activeKey={selectedMenuTabItem}
        onChange={onChangeTab}
        type="card"
        tabPosition={tabPosition}
        style={{ minHeight: 200 }}
      >
        <Tabs.TabPane
          key="1"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={appsIcon}
                alt="dashboard"
                style={{ width: "16px", marginRight: "8px" }}
              />
              Dashboard
            </span>
          }
        >
          <Dashboard />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="2"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={dashboardIcon}
                alt="dashboard"
                style={{ width: "16px", marginRight: "8px" }}
              />
              Billing
            </span>
          }
        >
          <Billing />
        </Tabs.TabPane>

        <Tabs.TabPane
          key="3"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={countrywiseOTPCountIcon}
                alt="Country-Wise OTP Count"
                style={{ width: "16px", marginRight: "8px" }}
              />
              Country-Wise OTP Count
            </span>
          }
        >
          <CountryWiseOTPCount />
        </Tabs.TabPane>

        <Tabs.TabPane
          key="4"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={monthwiseOTPcountIcon}
                alt="
             Month-Wise OTP Count"
                style={{ width: "16px", marginRight: "8px" }}
              />
              Month-Wise OTP Count
            </span>
          }
        >
          <MonthWiseOTPCount />
        </Tabs.TabPane>

        <Tabs.TabPane
          key="5"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={teamIcon}
                alt="Teams Manager"
                style={{ width: "16px", marginRight: "8px" }}
              />
              Teams Manager
            </span>
          }
        >
          <Teams />
        </Tabs.TabPane>

        <Tabs.TabPane
          key="6"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={teamleadIcon}
                alt="Team Leads Manager"
                style={{ width: "16px", marginRight: "8px" }}
              />
              Team Leads Manager
            </span>
          }
        >
          <TeamLeads />
        </Tabs.TabPane>

        <Tabs.TabPane
          key="7"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={testerIcon}
                alt="dashboard"
                style={{ width: "16px", marginRight: "8px" }}
              />
              Testers Manager
            </span>
          }
        >
          <Testers />
        </Tabs.TabPane>

        <Tabs.TabPane
          key="8"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={testerIcon}
                alt="dashboard"
                style={{ width: "16px", marginRight: "8px" }}
              />
              Blocked Testers
            </span>
          }
        >
          <BlockedTesters />
        </Tabs.TabPane>

        <Tabs.TabPane
          key="9"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={leaderboardIcon}
                alt="dashboard"
                style={{ width: "16px", marginRight: "8px" }}
              />
              Leader Board
            </span>
          }
        >
          <Leaderboard />
        </Tabs.TabPane>

        <Tabs.TabPane
          key="10"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={simcardIcon}
                alt="dashboard"
                style={{ width: "16px", marginRight: "8px" }}
              />
              Sim Cards Manager
            </span>
          }
        >
          <SimCards />
        </Tabs.TabPane>

        <Tabs.TabPane
          key="11"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={appsIcon}
                alt="dashboard"
                style={{ width: "16px", marginRight: "8px" }}
              />
              Applications Manager
            </span>
          }
        >
          <Applications />
        </Tabs.TabPane>

        <Tabs.TabPane
          key="12"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={otpStatsIcon}
                alt="dashboard"
                style={{ width: "16px", marginRight: "8px" }}
              />
              Manual Testing Team Statistics
            </span>
          }
        >
          <Stats />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="13"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={otpStatsIcon}
                alt="dashboard"
                style={{ width: "16px", marginRight: "8px" }}
              />
              Special Testing Team Statistics
            </span>
          }
        >
          <SpecialTeamStats />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="14"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={TesterstatisticsIcon}
                alt="dashboard"
                style={{ width: "16px", marginRight: "8px" }}
              />
              Tester Statistics
            </span>
          }
        >
          <Testerstatistics />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="15"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={TesterstatisticsIcon}
                alt="dashboard"
                style={{ width: "16px", marginRight: "8px" }}
              />
              Special Tester Statistics
            </span>
          }
        >
          <SpecialTesterstatistics />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="16"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={billIcon}
                alt="dashboard"
                style={{ width: "16px", marginRight: "8px" }}
              />
              Payment and Valid OTP for last month
            </span>
          }
        >
          <AllTestersStats />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="17"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={billIcon}
                alt="dashboard"
                style={{ width: "16px", marginRight: "8px" }}
              />
              All Testers Banned Apps OTP Count
            </span>
          }
        >
          <AlltestersBannedAppsOtpCount />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="18"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={configSettingsIcon}
                alt="dashboard"
                style={{ width: "16px", marginRight: "8px" }}
              />
              Configuration Settings
            </span>
          }
        >
          <ConfigSettings />
        </Tabs.TabPane>

        <Tabs.TabPane
          key="19"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={frequentlyUsedIcon}
                alt="dashboard"
                style={{ width: "16px", marginRight: "8px" }}
              />
              Frequently Used Apps For manual testers
            </span>
          }
        >
          <FrequentApps />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="20"
          tab={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={frequentlyUsedIcon}
                alt="dashboard"
                style={{ width: "16px", marginRight: "8px" }}
              />
              Frequently Used Apps for special testers
            </span>
          }
        >
          <AppWiseCount />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

const items = [
  {
    key: "1",
    label: `EON Testing Tool`,
    component: View1,
  },
];

export default TeamleadDashboard;
