import React, { useState, useEffect, useRef, useMemo } from "react";
import { Button, Input, Space, Table, message, Modal, Form } from "antd";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import { ExpandAltOutlined, PlusOutlined } from "@ant-design/icons";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Stats from "./tester-stats";

const apiUrl = process.env.REACT_APP_API_TesterStatsForTeamlead;
const dummyApi = "https://jsonplaceholder.typicode.com/posts"; // Dummy API endpoint

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Testers = () => {
  const [testers, setTesters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [openBonusModal, setOpenBonusModal] = useState(false); // State for bonus modal
  const [selectedTester, setSelectedTester] = useState(null);
  const [bonusTitle, setBonusTitle] = useState("");
  const [bonusAmount, setBonusAmount] = useState("");
  const [loading, setLoading] = useState(true);
  const [sendingBonus, setSendingBonus] = useState(false);
  const searchInput = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    fetchData();
  }, []);

  const storedUser = localStorage.getItem("user");
  const user = JSON.parse(storedUser);

  const sortedUsers = useMemo(() => {
    return [...testers].sort((a, b) => b.otps_count - a.otps_count);
  }, [testers]);

  const fetchData = async () => {
    try {
      let apiEndpoint = apiUrl;
      if (user.userId === 478) {
        apiEndpoint =
          "https://jqh0cp25wl.execute-api.us-east-1.amazonaws.com/dev/special-testers-stats-for-team-lead";
      } else {
        apiEndpoint = `${apiUrl}/${user.userId}`;
      }

      const response = await fetch(apiEndpoint);
      if (!response.ok) throw new Error("Failed to fetch testers");
      const data = await response.json();
      setTesters(data || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching testers:", error);
    }
  };

  const handleAddBonusClick = (record) => {
    setSelectedTester(record);
    setOpenBonusModal(true);
  };

  const handleBonusSubmit = async () => {
    if (!bonusTitle || !bonusAmount) {
      messageApi.error("Please enter both Bonus Title and Amount");
      return;
    }

    setSendingBonus(true);

    try {
      const payload = {
        user_id: selectedTester.id,
        bonus_title: bonusTitle,
        amount: Number(bonusAmount),
      };

      await axios.post(dummyApi, payload);

      messageApi.success("Bonus added successfully!");
      setOpenBonusModal(false);
      setBonusTitle("");
      setBonusAmount("");
    } catch (error) {
      console.error("Error sending bonus:", error);
      messageApi.error("Failed to add bonus. Try again.");
    } finally {
      setSendingBonus(false);
    }
  };

  const columns = [
    {
      title: "Tester Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
    },
    {
      title: "Total Tests This Month",
      dataIndex: "current_month_otps_count",
      key: "current_month_otps_count",
      sorter: (a, b) => a.current_month_otps_count - b.current_month_otps_count,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button icon={<ExpandAltOutlined />} onClick={() => setOpen(true)}>
            View Details
          </Button>
          <Button
            icon={<PlusOutlined />}
            onClick={() => handleAddBonusClick(record)}
          >
            Add Bonus
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      {contextHolder}

      <Table
        columns={columns}
        dataSource={sortedUsers}
        loading={loading}
        pagination={{ showSizeChanger: true, defaultPageSize: 5 }}
      />

      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
              Tester Statistics Details
            </Typography>
            <Typography>
              {selectedTester?.first_name} {selectedTester?.last_name}
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <Grid container justifyContent="center">
            <Grid container style={{ width: "80%" }}>
              {selectedTester && <Stats id={selectedTester.id} />}
            </Grid>
          </Grid>
        </List>
      </Dialog>

      <Modal
        title="Add Bonus"
        open={openBonusModal}
        onCancel={() => setOpenBonusModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setOpenBonusModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={sendingBonus}
            onClick={handleBonusSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Bonus Title" required>
            <Input
              value={bonusTitle}
              onChange={(e) => setBonusTitle(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Amount" required>
            <Input
              type="number"
              value={bonusAmount}
              onChange={(e) => setBonusAmount(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Testers;
